// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Change the hex values below to alter the main color scheme.

$popperi-pink-lightest: #f48aa3;
$popperi-pink-light: #f48aa3;
$popperi-pink: #ED3D66;
$popperi-pink-darker: #e21545;
$popperi-pink-darkest: #850c28;
$popperi-green-bright: #0ce4d9;
$popperi-green: #20B2AA;
$popperi-green-dark: #167873;
$popperi-green-darkest: #0e4d4a;
$popperi-yellow: #FFE600;
$popperi-yellow-muted: #fff7aa;
$popperi-purple-lightest: #f6f3f5;
$popperi-purple-light: #b78ba8;
$popperi-purple: #7E4E6D;
$popperi-purple-dark: #3d2d38;
$popperi-purple-darkest: #0c090b;
$popperi-light: $popperi-purple-lightest;

$primary:        $popperi-pink;
$primary-2:      $popperi-pink-darker; 
$primary-3:      $popperi-pink-darkest;
$secondary:      $popperi-green; 
$success:        $popperi-purple-light;
$info:           $popperi-green-bright;
$light:          $popperi-light;
$dark:           $popperi-purple-dark; 
$bright:         $popperi-yellow;
$bright-muted:   $popperi-yellow-muted;
$headings-color: $popperi-purple-darkest;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "light":      $light,
    "dark":       $dark
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "light":      $light,
    "dark":       $dark
);

/* Fonts */
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.3;
$font-size-sm:                $font-size-base * .875;
$h1-font-size:                $font-size-base * 2.6;
$h3-font-size:                $font-size-base * 1.3;
$font-weight-normal:          500;
$font-weight-base:  $font-weight-normal;


/* Buttons and Forms */
$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .75rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:       .2rem !default;
$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;
$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      .4rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;
$input-btn-line-height-lg:    $line-height-lg !default;

$input-btn-border-width:      $border-width !default;

$btn-border-radius:           40px;
$btn-border-radius-lg:        $btn-border-radius;
$btn-border-radius-sm:        $btn-border-radius;
