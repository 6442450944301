// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.bg-light {
    background-color: $light !important;
}

.bg-bright {
    background-color: $bright !important;
    color: black;
}   

.bg-bright-muted {
    background-color: $bright-muted !important;
    color: black;
}   

mark {
    background-image: linear-gradient(120deg, rgba($bright, .8) 0%, rgba($bright, .8) 100%) !important;
}  

.card.bg-white {
    background-color: rgba(255, 255, 255, 0.17) !important;
}

.footer-legal {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 100px;
    padding: 30px 0;
}

.circle-number {
    height: 1px;
    width: 1px;
    color: white;
}

h1, .h1 {
    font-weight: 900 !important;
    letter-spacing: 0.03rem !important;
    line-height: 1.29 !important;
}

button, .btn {
    letter-spacing: -0.05rem;
}

.lead {
    line-height: 2.1rem;
    font-size: $font-size-lg;
}

nav a {
    color: $secondary;
}


.bg-light .border-top { 
    border-color: #d5dbe5 !important;
}

.alert-bright {
    background: $bright;
}

.text-bright {
    color: $bright !important;
}

.border-bright {
    border-color: $bright !important;
    border: 3px solid;
}

.event-card {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.card-rounded {
    border-radius: 30px;
}

.active-page {
    cursor: default;
    color: $primary !important;
    text-decoration: none;  
}

.active:hover {
    color: $primary !important;
}